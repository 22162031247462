import React, { useState } from "react";
import { colors } from "../../styles/colors";
import logo from "../../assets/logos/logo.svg";
import { requestOtp, validateOtp } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import WAI from "../../assets/logos/wiai.png";
import USAID from "../../assets/logos/usaid.png";
import NTEP from "../../assets/logos/ntep.png";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../../features/global/globalSlice";
const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
    setPhoneError("");
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
    setOtpError("");
  };

  const validatePhoneNumber = (): boolean => {
    const phoneNumberRegex = /^\d{10}$/;
    if (!phoneNumberRegex.test(phoneNumber)) {
      setPhoneError("Please enter a valid 10-digit phone number.");
      return false;
    }
    return true;
  };

  const validateOtpInput = (): boolean => {
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      setOtpError("Please enter a valid 6-digit OTP.");
      return false;
    }
    return true;
  };

  const handleNext = async () => {
    if (!validatePhoneNumber()) return;

    setLoading(true);
    try {
      const response = await requestOtp(phoneNumber);
      if (response.status === 200) {
        setOtpSent(true);
      } else {
        alert("Something went wrong, please try again later.");
      }
    } catch {
      alert("Something went wrong, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validateOtpInput()) return;

    setLoading(true);
    try {
      const response = await validateOtp(phoneNumber, otp);
      if (response.status === 401) {
        setOtpError("Invalid OTP, please try again.");
      } else if (response.ok) {
        const content = await response.json();
        if (content.new_user) {
          navigate("/user-details", { state: { number: phoneNumber } });
        } else {
          localStorage.setItem("user-details", JSON.stringify(content));
          dispatch(setAuthenticated(true));
          navigate("/patient-details");
        }
      }
    } catch {
      setOtpError("An error occurred while verifying OTP, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems={"center"}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: "50%", maxWidth: "200px" }}
        />
        <Typography variant="h3">CoughSync</Typography>
      </Box>
      <Box mt={4}>
        {!otpSent ? (
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
            phoneError={phoneError}
            loading={loading}
            onNext={handleNext}
          />
        ) : (
          <OtpInput
            otp={otp}
            onOtpChange={handleOtpChange}
            otpError={otpError}
            loading={loading}
            onSubmit={handleSubmit}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems={"center"} mt={4}>
        {/* <img
          src={USAID}
          alt="USAID Logo"
          style={{ width: "30%", marginRight: 8 }}
        /> */}
        <img
          src={NTEP}
          alt="NTEP Logo"
          style={{ width: "15%", marginLeft: 8 }}
        />
        <img
          src={WAI}
          alt="WAI Logo"
          style={{ width: "35%", marginLeft: 8 }}
        />
      </Box>
    </Container>
  );
};

const PhoneNumberInput: React.FC<{
  phoneNumber: string;
  onPhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneError: string;
  loading: boolean;
  onNext: () => void;
}> = ({ phoneNumber, onPhoneNumberChange, phoneError, loading, onNext }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="body1" component="label" htmlFor="phoneNumber" mb={1}>
      Phone Number
    </Typography>
    <TextField
      fullWidth
      id="phoneNumber"
      value={phoneNumber}
      onChange={onPhoneNumberChange}
      error={!!phoneError}
      helperText={phoneError}
      variant="outlined"
    />
    <Button
      onClick={onNext}
      disabled={loading}
      variant="contained"
      fullWidth
      sx={{
        backgroundColor: colors.primary,
        color: colors.secondary,
        mt: 2,
      }}
      endIcon={!loading ? <SendIcon /> : null}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
    </Button>
  </Box>
);

const OtpInput: React.FC<{
  otp: string;
  onOtpChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  otpError: string;
  loading: boolean;
  onSubmit: () => void;
}> = ({ otp, onOtpChange, otpError, loading, onSubmit }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="body1" component="label" htmlFor="otp" mb={1}>
      OTP
    </Typography>
    <TextField
      fullWidth
      id="otp"
      value={otp}
      onChange={onOtpChange}
      error={!!otpError}
      helperText={otpError}
      variant="outlined"
    />
    <Button
      onClick={onSubmit}
      disabled={loading}
      variant="contained"
      fullWidth
      sx={{
        backgroundColor: colors.primary,
        color: colors.secondary,
        mt: 2,
      }}
      endIcon={!loading ? <CheckIcon /> : null}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : "Verify"}
    </Button>
  </Box>
);

export default Login;
